<template>
  <div class="home" :class="{'mobile-main':isMobile}">
    <div class="home-nav">
		<img src="../assets/logo.png" class="logo-img"/>
		<img src="../assets/language.jpg" class="language-img"/>
	</div>
	<div style="height: 63px;"></div>
	
	<img  src="../assets/luno-featured-en-GB.png" class="featured-img" v-if="!isMobile"/>
	
	<img  src="../assets/luno-featured-en-GB.png" class="featured-mobile-img" v-if="isMobile"/>
	
	<div class="module-noe module-main">
		<h1 class="module-noe-tel font-color">购买、存储和探索 加密货币</h1>
		<div class="btn-lsin">
			<div class="module-noe-btn pointers" @click="jump">了解更多</div>
		</div>
		
	</div>
	
	<div class="module-main secure-list">
		<div class="secure-item">
			<img src="../assets/dianzan.png" class="secure-img"/>
			<div class="secure-tel">支持</div>
			<div class="secure-text">获取管理您的加密货币和Gemini帐户的工具和知识</div>
		</div>
		<div class="secure-item">
			<img src="../assets/anquan.png" class="secure-img"/>
			<div class="secure-tel">安全</div>
			<div class="secure-text">根据 CCData 2023 年 4 月的排名，您受到 行业领先的安全保护</div>
		</div>
		<div class="secure-item">
			<img src="../assets/shequ.png" class="secure-img"/>
			<div class="secure-tel">社区</div>
			<div class="secure-text">成立于 2013 年，全球有数百万客户使用。</div>
		</div>
	</div>
	
	<div class="module-main choose-mian">
		<div class="choose-left">
			<img src="../assets/security-device-hidden.png" class="choose-left-img"/>
		</div>
		<div>
			<div class="choose-title">为什么选择 Gemini?</div>
			<div class="choose-text">Gemini 旨在成为全球最合规的加密货币投资平台之一。 根据 CCData 2023 年 4 月的排名，我们采取监管优先的方法，并拥有一些加密领域最密集的安全流程。</div>
			<div class="choose-text">在发布之前，我们会根据 Gemini 内部尽职调查标准对所有加密货币和新功能进行审查， 并提供简单的信息来帮助您了解风险，以便您可以制定和执行适合您的投资策略。</div>
			<div class="choose-text">请注意，Gemini 不受监管，加密资产属于高风险投资，基本上不受监管。</div>
			
			<div class="choose-btns pointers" @click="jump">了解更多</div>
		</div>
	</div>
	
	<div class="module-main way-mian">
		<div class="way-item">
			<div class="way-item-tel">为了您的安全</div>
			<div class="way-item-text">我们遵循严格的 KYC 和 AML 程序。</div>
			<img src="../assets/sign-up.png" class="way-img"/>
		</div>
		<div class="way-item">
			<div class="way-item-tel">选择您的投资</div>
			<div class="way-item-text">您可以投资一系列加密货币。</div>
			<img src="../assets/choose-your-crypto.png" class="way-img"/>
		</div>
		<div class="way-item">
			<div class="way-item-tel">你的支付方式</div>
			<div class="way-item-text">有多种不同的付款方式可选择。</div>
			<img src="../assets/buy-crypto.png" class="way-img"/>
		</div>
	</div>
	
	<div class="way-boom">
		<div class="way-btns pointers" @click="jump">了解更多</div>
	</div>
	
	<div class="module-main study-main">
		<div class="study-tels">自己进行研究</div>
		<div class="study-texts">无论您是加密货币新手还是经验丰富的投资者，您总能在 Gemini 中找到可以帮助您做出投资决策的内容，这是您了解所有加密货币的指南。</div>
		
		<div class="study-lists">
			<div class="study-item" @click="jump">
				<img src="../assets/study1.jpg" class="study-img"/>
				<div class="study-info">
					<div class="study-name">了解您的投资期限</div>
					<div class="study-inserted">任何投资之旅都始于分析您的需求和目标以及您想要实现这些目标的时间范围。 这是至关重要的第一步，将决定您的投资组合是什么样子。 例如，退休的长期财务规划将不同于房屋首付的投资计划。</div>
				</div>
				
				<img src="../assets/jiantou-rets.png" class="jiantou-img"/>
			</div>
			<div class="study-item" @click="jump">
				<img src="../assets/study2.jpg" class="study-img"/>
				<div class="study-info">
					<div class="study-name">投资加密货币时需要考虑的 5 件事</div>
					<div class="study-inserted">投资并不总是涉及复杂的图表和难以理解的金融术语。 在大多数时候，实际上更多的是制定一种方法来实现你的目标并保持头脑清醒。</div>
				</div>
				<img src="../assets/jiantou-rets.png" class="jiantou-img"/>
			</div>
			<div class="study-item" @click="jump">
				<img src="../assets/study3.jpg" class="study-img"/>
				<div class="study-info">
					<div class="study-name">什么是比特币?</div>
					<div class="study-inserted">比特币最初创建于 2009 年，是一种专为互联网时代打造的数字货币形式。它第一个解决了困扰其他数字货币的欺诈问题，其采用革命性的加密算法来确保其安全性。</div>
				</div>
				<img src="../assets/jiantou-rets.png" class="jiantou-img"/>
			</div>
		</div>
	</div>
	
	
	<div class="module-main make-main">
		<div class="make-tel">创建您的加密货币投资组合</div>
		<div class="make-mxy">Gemini 拥有帮助您实现财务目标的工具和信息</div>
		
		
		<div class="make-list">
			<div class="make-mune">
				<div class="make-mune-item">
					<div class="mune-img-s">
						<img src="../assets/guanli.png" class="make-mune-img"/>
					</div>
					
					<div>
						<div class="make-mune-name">管理您的加密货币</div>
						<div class="make-mune-text">每天 24 小时都可以通过 Gemini 程序购买、出售和存储您的加密货币。</div>
					</div>
				</div>
				<div class="make-mune-item" style="margin-top: 30px;">
					<div class="mune-img-s">
						<img src="../assets/goumai.png" class="make-mune-img"/>
					</div>
					
					<div>
						<div class="make-mune-name">定期购买</div>
						<div class="make-mune-text">通过每天、每周或每月的重复购买，随着时间的推移缓慢投资，以增加您的持仓。</div>
					</div>
				</div>
				<div class="make-mune-item" style="margin-top: 30px;">
					<div class="mune-img-s">
						<img src="../assets/tansuo.png" class="make-mune-img"/>
					</div>
					
					<div>
						<div class="make-mune-name">发现 / 探索</div>
						<div class="make-mune-text">洞察力可帮助您扩展知识并帮助您做出明智的决策。</div>
					</div>
				</div>
			</div>
			
			<div class="make-list-ret">
				<img src="../assets/multi-platform-portfolio-en-GB.png" class="make-ret-img"/>
			</div>
		</div>
	</div>
	
	<div class="cooperate-mian">
		<div class="module-main cooperate-content">
			<div class="cooperate-tel">合作伙伴</div>
			
			<div class="cooperate-list">
				<img src="../assets/the-banker-logo.png" style="width: 127px;height: 40px;"/>
				<img src="../assets/the-block-logo.png" style="width: 198px;height: 40px;"/>	
				<img src="../assets/cnbc-logo.png" style="width: 63px;height: 40px;"/>
				<img src="../assets/coindesk-logo.png" style="width: 208px;height: 40px;"/>
				<img src="../assets/cityam-logo.png" style="width: 173px;height: 40px;"/>
				<img src="../assets/financial-times-logo.png" style="width: 298px;height: 40px;"/>
			</div>
		</div>
	</div>
	
	<div class="home-time">2023 年 6 月 10 日</div>
	<div class="home-pzs">获得 Archadx Ltd 批准</div>
	
	<div class="module-main copyright-main">
		<div class="copyright-module">
			<div class="copyright-tel">投资加密资产可能会导致资产损失</div>
			<div class="copyright-tel">尽管本网站上的信息和数据都是可靠来源，但仅供参考。 该信息并不构成 Gemini 购买或出售加密货币的建议， 也不构成投资加密货币的建议、诱因或推荐。</div>
			
			<div class="copyright-text">版权所有 © 2024</div>
		</div>
	</div>
	
	<div style="height: 40px;"></div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
	  return {
		  isMobile: false,
		  timer: null
	  }
  },
  mounted() {
  	// 添加事件监听
  	this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  	window.addEventListener('resize', this.handleResize);
  },
  methods: {
	  handleResize() {
	  	clearTimeout(this.timer);
	  	this.timer = setTimeout(() => {
	  		this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
	  	}, 100);
	  },
	  
	  jump(){
		  this.$router.push({ name: 'about'});
	  }
  }
}
</script>

<style lang="scss">
@import '../assets/css/mobile.scss';
.font-color{
	color: #071278
}

.featured-mobile-img{
	width: 100%;
	height: 384px;
	margin-top: 60px;
	object-fit: cover;
	object-position: top left;
}
.copyright-main{
	padding: 0px 40px;
	.copyright-module{
		border: 1px solid #071278;
		padding: 24px;
		.copyright-tel{
			margin-top: 12px;
			color: #071278;
			font-size: 16px;
			font-weight: bold;
		}
		.copyright-text{
			margin-top: 12px;
			color: #071278;
			font-size: 16px;
		}
	}
}
.home-pzs{
	text-align: center;
	color: #4d62ab;
	font-size: 18px;
	margin-top: 12px;
	margin-bottom: 35px;
}

.home-time{
	text-align: center;
	color: #4d62ab;
	font-size: 18px;
	margin-top: 25px;
}

.cooperate-mian{
	margin-top: 70px;
	background-color: #f1f7fd;
	padding: 64px 0px;
	.cooperate-content{
		.cooperate-tel{
			color: #001e87;
			font-size: 24px;
			font-weight: bold;
		}
		.cooperate-list{
			display: flex;
			align-items: center;
			gap: 30px;
			margin-top: 20px;
			flex-wrap: wrap;
		}
	}
}
.make-main{
	padding-top: 120px;
	.make-list-ret{
		width: 760px;
		height: 328px;
		display: flex;
		align-items: center;
		justify-content: center;
		.make-ret-img{
			width: 495px;
			height: 328px;
		}
	}
	.make-list{
		margin-top: 50px;
		display: flex;
		align-items: center;
		gap: 30px;
		.make-mune{
			.make-mune-item{
				display: flex;
				gap: 30px;
				.make-mune-img{
					width: 26px;
					height: 26px;
				}
				.make-mune-name{
					color: #10251c;
					font-size: 18px;
					font-weight: bold;
				}
				.make-mune-text{
					color: #4d62ab;
					font-size: 18px;
					margin-top: 8px;
				}
			}
		}
	}
	.make-tel{
		color: #001e87;
		font-size: 40px;
	}
	.make-mxy{
		color: #4d62ab;
		font-size: 18px;
		margin-top: 10px;
	}
}
.study-main{
	padding-top: 120px;
	.study-tels{
		color: #001e87;
		font-size: 40px;
	}
	.study-texts{
		color: #4d62ab;
		font-size: 18px;
		margin-top: 10px;
	}
	.study-lists{
		margin-top: 50px;
		display: flex;
		align-items: center;
		gap: 20px;
		.study-item{
			flex: 1;
			border-radius: 10px;
			border: 1px solid #071278;
			position: relative;
			height: 460px;
			cursor: pointer;
			.jiantou-img{
				position: absolute;
				bottom: 16px;
				right: 16px;
				width: 16px;
				height: 16px;
			}
			.study-info{
				padding: 24px;
				.study-name{
					color: #001e87;
					font-size: 24px;
					font-weight: bold;
				}
				.study-inserted{
					color: #4d62ab;
					font-size: 18px;
					margin-top: 10px;
				}
			}
			.study-img{
				width: 100%;
				height: 213px;
				border-radius: 10px 10px 0px 0px;
			}
		}
	}
}

.way-boom{
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	.way-btns{
		width: 216px;
		height: 52px;
		border-radius: 25px;
		background-color: #162af1;
		text-align: center;
		line-height: 52px;
		color: #fff;
		font-size: 16px;
	}
}

.way-mian{
	padding-top: 160px;
	display: flex;
	align-items: center;
	gap: 20px;
	.way-item{
		flex: 1;
		border-radius: 10px;
		border: 1px solid #071278;
		height: 380px;
		position: relative;
		.way-img{
			position: absolute;
			width: 100%;
			bottom: 0;
			height: 232px;
		}
		.way-item-tel{
			padding-top: 24px;
			padding-left: 24px;
			color: #10215c;
			font-size: 20px;
			font-weight: bold;
		}
		.way-item-text{
			color: #10215c;
			font-size: 18px;
			padding-left: 24px;
			padding-top: 10px;
		}
	}
}
.choose-mian{
	padding-top: 120px;
	display: flex;
	align-items: center;
	gap: 30px;
	.choose-title{
		color: #001e87;
		font-size: 40px;
	}
	.choose-text{
		color: #4d62ab;
		font-size: 18px;
		margin-top: 18px;
	}
	.choose-left{
		width: 570px;
		height: 380px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		.choose-left-img{
			width: 185px;
			height: 100%;
		}
	}
	.choose-btns{
		width: 216px;
		height: 52px;
		border-radius: 25px;
		background-color: #162af1;
		text-align: center;
		line-height: 52px;
		color: #fff;
		font-size: 16px;
		margin-top: 30px;
	}
}
.secure-list{
	padding-top: 30px;
	display: flex;
	// align-items: center;
	gap: 100px;
	.secure-item{
		flex: 1;
		.secure-img{
			width: 24px;
			height: 24px;
		}
		.secure-tel{
			font-size: 18px;
			font-weight: bold;
			margin-top: 5px;
		}
		.secure-text{
			color: #4d62ab;
			font-size: 18px;
			margin-top: 5px;
		}
	}
}
.module-main{
	width: 50%;
	margin: 0 auto;
}

.featured-img{
	position: absolute;
	top: 160px;
	right: 0;
	width: 950px;
	height: 700px;
}
.module-noe{
	
	padding: 290px 0px;
	.module-noe-tel{
		font-size: 44px;
		font-weight: 400;
	}
	.module-noe-btn{
		width: 216px;
		height: 52px;
		border-radius: 25px;
		background-color: #162af1;
		text-align: center;
		line-height: 52px;
		color: #fff;
		font-size: 16px;
	}
	
}
.home-nav{
	height: 63px;
	background-color: #000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	.logo-img{
		width: 40px;
		height: 40px;
	}
	.language-img{
		width: 24px;
		height: 24px;
	}
}
</style>
